<script>
import { get } from 'vuex-pathify'

export default {
  name: 'Block',

  components: {
    PageHeader: () => import('@/components/Blocks/PageHeader'),
    InfoBar: () => import('@/components/Blocks/InfoBar'),
    Cards: () => import('@/components/Blocks/CardsBlock'),
    Quotes: () => import('@/components/Blocks/QuotesBlock'),
    Content: () => import('@/components/Blocks/ContentBlock'),
    Embed: () => import('@/components/Blocks/EmbedBlock'),
    EmailForm: () => import('@/components/Blocks/EmailFormBlock'),
    Faq: () => import('@/components/Blocks/FaqBlock'),
    Features: () => import('@/components/Blocks/FeaturesBlock'),
    MapBlock: () => import('@/components/Blocks/MapBlock'),
    HorizontalLine: () => import('@/components/Blocks/HorizontalLine'),
    ImageBlock: () => import('@/components/Blocks/ImageBlock'),
  },

  props: {
    index: {
      type: Number,
      default: null,
    },

    item: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },

    parent: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },

    metaData: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    rowPosition() {
      return this.metaData.pos
    },

    hasBackground() {
      const { bgColor } = this.item.props
      return !!(bgColor?.color || bgColor?.gradient)
    },

    expandClass() {
      if (!this.metaData.rowHasExpandedCol) {
        return
      }

      const classes = {
        first: 'expand-left',
        last: 'expand-right',
      }

      if (this.rowPosition === 'only') {
        if (this.width === '1:1') {
          return 'expand-x'
        }

        if (this.sectionHalign === 'start') {
          return 'expand-left'
        }

        if (this.sectionHalign === 'end') {
          return 'expand-right'
        }
      }

      return this.metaData.isExpanded ? classes[this.rowPosition] : undefined
    },

    width() {
      return this.item.props.width
    },

    sectionHalign() {
      return this.parent.props.halign
    },

    hasBoxShadow() {
      return !!this.item.props.boxShadow
    },

    hasRoundedCorners: get('page/hasRoundedCorners'),

    isNarrowHeight() {
      return ['PageHeader', 'InfoBar'].includes(this.item.name)
    },

    isMedia() {
      return ['ImageBlock', 'MapBlock'].includes(this.item.name)
    },

    roundedCornersClass() {
      const { rowHasExpandedCol, allColsHaveBg, pos, rowHasFilledMedia } =
        this.metaData

      if (!this.hasRoundedCorners || rowHasExpandedCol) {
        return
      }

      const hasBgOrShadow = this.hasBackground || this.hasBoxShadow

      if (
        pos === 'only' ||
        (hasBgOrShadow && !allColsHaveBg && !rowHasFilledMedia)
      ) {
        return 'rounded-lg'
      }

      if (hasBgOrShadow && (allColsHaveBg || rowHasFilledMedia)) {
        return pos === 'first' ? 'rounded-l-lg' : 'rounded-r-lg'
      }

      return null
    },

    paddingClasses() {
      if (this.isNarrowHeight) {
        return {}
      }

      const {
        pos,
        hasBg,
        hasShadow,
        rowHasBgCol,
        isExpanded,
        rowHasExpandedCol,
        hasFilledMedia,
        rowHasFilledMedia,
      } = this.metaData

      if (
        !this.isMedia &&
        !hasShadow &&
        !hasBg &&
        rowHasFilledMedia &&
        !rowHasExpandedCol
      ) {
        return {
          'lg:px-16': !this.isMedia,
          'lg:py-4': true,
        }
      }

      return {
        'lg:px-16': !this.isMedia || (!hasFilledMedia && rowHasExpandedCol),

        'lg:py-12':
          ((hasShadow || hasBg) && !isExpanded && !rowHasExpandedCol) ||
          (!this.isMedia &&
            (rowHasBgCol || rowHasFilledMedia) &&
            !rowHasExpandedCol) ||
          (!this.isMedia && rowHasExpandedCol && pos === 'only'),

        'lg:py-20':
          (!hasFilledMedia && rowHasExpandedCol) ||
          (isExpanded && pos !== 'only'),
      }
    },
  },

  render(h) {
    const {
      hasBg,
      pos,
      rowHasBgCol,
      rowHasExpandedCol,
      // hasFilledMedia,
      isExpanded,
      prevRowHasExpandedCol,
    } = this.metaData

    return h(this.item.name, {
      props: { ...this.$props, ...{ item: this.item } },
      style: {},
      class: [
        this.expandClass,
        this.roundedCornersClass,
        'min-h-full mob:px-6',
        {
          ...this.paddingClasses,
          'shadow-lg': this.hasBoxShadow,
          'row-has-expanded-col': rowHasExpandedCol,

          // 'lg:-mr-6 lg:pr-16':
          //   (rowHasExpandedCol || (hasFilledMedia && isExpanded)) &&
          //   pos === 'first',
          // 'lg:-ml-6 lg:pl-16':
          //   (rowHasExpandedCol || (hasFilledMedia && isExpanded)) &&
          //   pos === 'last',

          'lg:ml-12': this.isMedia && !rowHasBgCol && pos === 'first',
          'lg:mr-12': this.isMedia && !rowHasBgCol && pos === 'last',
          'lg:-mt-16': rowHasExpandedCol && prevRowHasExpandedCol,

          // Mobile

          'mob:py-10': hasBg,
          'mob:my-10': !isExpanded && !this.isNarrowHeight,
          'mob:mx-6': hasBg && !isExpanded,
        },
      ],
    })
  },
}
</script>
